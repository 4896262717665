<template>
  <div
    v-show="selectedOffers.length"
    class="relative transition-all flex justify-end z-90"
  >
    <delete-offer-modal
      v-if="showDeleteOfferModal"
      :selectedOffers="selectedOffers"
      :offers="offers"
      @offerDeleted="$emit('offerDeleted')"
      :close="() => (showDeleteOfferModal = false)"
    />
    <div
      class="absolute -top-4 transform -translate-y-full flex flex-col items-center z-10 min-w-max"
      :class="[
        showTooltip ? 'visible' : 'invisible',
        selectedOffers.length === 1 ? '-right-8' : '-right-4'
      ]"
    >
      <span
        class="relative p-2 pb-4 text-s font-light leading-relaxed text-white whitespace-no-wrap bg-gray-800 shadow-lg rounded-lg"
      >
        {{ toolTipText }}
      </span>
      <div class="w-3 h-3 -mt-2 transform rotate-45 bg-gray-800 z-20"></div>
    </div>
    <div
      class="flex border rounded p-2"
      :class="{
        'hover:bg-red-100 hover:text-red-500': selectedOffers.length === 1,
        'hover:bg-red-500 hover:text-white': selectedOffers.length > 1,
        'pr-10 pl-4 py-3': selectedOffers.length > 1
      }"
      @click="showDeleteOfferModal = true"
      @mouseenter="showTooltip = true"
      @mouseleave="showTooltip = false"
    >
      <icon-base icon="delete-offer" />
      <span
        class="absolute ml-6 bg-gray-200 bg-opacity-50 rounded-full h-5 w-5 flex items-center justify-center text-xxs"
        v-if="selectedOffers.length > 1"
      >
        {{ selectedOffers.length }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IOffer } from "@/models/funders";
import DeleteOfferModal from "@/views/deals/modals/DeleteOfferModal.vue";
import type { PropType } from "vue";
import { ref, computed } from "vue";

const props = defineProps({
  selectedOffers: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  offers: {
    type: Array as PropType<IOffer[]>,
    default: () => []
  },
  isSelfFunding: {
    type: Boolean,
    default: false
  }
});

const showTooltip = ref(false);
const showDeleteOfferModal = ref(false);

const toolTipText = computed(() =>
  props.selectedOffers.length === 1
    ? "Delete Offer"
    : `Delete ${props.selectedOffers.length} offers`
);
</script>
